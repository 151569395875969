// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = [''];
// var subsiteHijackMainLogoClickDestination = ['engadine-district-youth-service', 'djanaba-occasional-care-centre'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links


// Subsite logo link
$(".subsite.engadine-district-youth-service .subsiteTitle").contents().unwrap().wrap("<a class='subsiteTitle' href='/engadine-district-youth-service' />");
$(".subsite.toolboxtalks .subsiteTitle").contents().unwrap().wrap("<a class='subsiteTitle' href='/toolboxtalks' />");
$(".subsite.sutherland-shire-walk-for-respectful-relationships .subsiteTitle").contents().unwrap().wrap("<a class='subsiteTitle' href='/sutherland-shire-walk-for-respectful-relationships' />");
$(".subsite.gala-dinner .subsiteTitle").contents().unwrap().wrap("<a class='subsiteTitle' href='/gala-dinner' />");

// Show quick exit on subsites DES-4778
$('.footerBox a.exit-site').appendTo('.pageFooter');